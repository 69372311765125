.mainContainer {
  background-color: #e8e8e8;
  margin: 0;
  width: 100%;
  position: relative;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Mak';
  src: url('./assets/fonts/MAK.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/MAK.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/MAK.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/MAK.ttf') format('truetype'); /* Safari, Android, iOS */
           font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Mak';
  src: url('./assets/fonts/MAK-bold.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/MAK-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/MAK-bold.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/MAK-bold.ttf') format('truetype'); /* Safari, Android, iOS */
           font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Kenyan';
  src: url('./assets/fonts/kenyan.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/kenyan.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/kenyan.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/kenyan.ttf') format('truetype'); /* Safari, Android, iOS */
           font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Kenyan';
  src: url('./assets/fonts/kenyan-bold.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/kenyan-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/kenyan-bold.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/kenyan-bold.ttf') format('truetype'); /* Safari, Android, iOS */
           font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Prag';
  src: url('./assets/fonts/PragmaticaCondLight.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/PragmaticaCondLight.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/PragmaticaCondLight.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/PragmaticaCondLight.ttf') format('truetype'); /* Safari, Android, iOS */
           font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Prag';
  src: url('./assets/fonts/PragmaticaCondMedium.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/PragmaticaCondMedium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/PragmaticaCondMedium.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/PragmaticaCondMedium.ttf') format('truetype'); /* Safari, Android, iOS */
           font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

body {
  font-family: 'Prag', sans-serif;
  font-weight: normal;
}

div {
  position: relative;
}

h1 {
  font-family: 'Mak', sans-serif;
  font-weight: bold;
  font-size: xxx-large;
}

h2 {
  font-family: 'Kenyan', sans-serif;
  font-weight: bold;
}

p {
  margin: 0px;
  line-height: normal;
  font-family: 'Prag', sans-serif;
}

a {
  text-decoration: none;
}

.fmak {
  font-family: 'Mak', sans-serif;
}

.fkenyan {
  font-family: 'Kenyan', sans-serif;
}

.fprag {
  font-family: 'Prag', sans-serif;
}

.magSection {
  margin: 8px;
  border-radius: 4px;
  padding: 12px;
  display: flex;
}

.bold {
  font-weight: bold;
}

.medium {
  font-size: medium;
}

.large {
  font-size: larger;
}

.xlarge {
  font-size: x-large;
}

.xxlarge {
  font-size: xx-large;
}

.xxxlarge {
  font-size: 3.5em;
}

.huge {
  font-size: 5em;
}

.small {
  font-size: small;
}

.hugeScreen {
  font-size: clamp(2.5rem, 5vw, 4rem);
}

.bigScreen {
  font-size: clamp(1rem, 3vw, 1.4rem);
}

.center {
  text-align: center;
}

.fullSearchBar {
  position: fixed;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 46px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.search input {
  background-color: white;
  border: 0;
  border-radius: 8px;
  font-size: 16px;
  padding: 15px 32px 15px 15px;
  height: 30px;
  width: 240px;
  color: black;
}

.search {
  position: relative;
  margin-right: 8px;
}

.searchIcon {
  position: absolute;
  right: 4px;
  top: 2px;
  color: black;
}

.dataResult {
  position: fixed;
  top: 64px;
  left: 0px;
  width: 100%;
  height: calc(100vh - 64px);
  background-color: black;
  overflow-y: auto;
  z-index: 999;
  padding-left: 12px;
  padding-right: 12px;
  color: white;
  font-weight: lighter;
  line-height: 1.29;
  -webkit-overflow-scrolling: touch;
}

.olDataResult {
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  border: none;
  outline: none !important;
}

.closeButtonIcon {
  font-size: 26px;
  cursor: pointer;
  color: black;
}

.closeButtonIcon:hover {
  color: #ff7676;
}

.menuDivIcon {
  margin-left: 8px;
  margin-right: 8px;
  font-size: 26px;
  display: flex;
  cursor: pointer;
}

.menuDivIcon:hover {
  color: #ff7676;
}

.menuDivText {
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
  color: white;
}

.menuDivText:hover {
  color: #ff7676;
}

.buttonShadow {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  width: fit-content;
  font-size: x-large;
  border-radius: 18px;
  padding: 0px 12px 2px 12px;
  border: solid thin transparent;
  transition: border-color 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.buttonShadow:hover {
  border-color: currentColor;
  filter: hue-rotate(-15deg);
}

.squareFrame {
  margin-left: 40px;
  border: solid black;
  height: 200px;
  width: 200px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.square {
  width: 100%;
  padding-top: 100%; 
  position: relative; 
}

.square-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gainsboro;
}

.categoryCardTitle {
  margin-left: 40px;
  font-family: 'Kenyan';
  font-size: large;
  width: 150px;
  line-height: normal;
  height: 64px;
  display: flex;
  align-items: flex-end;
}

.categoryCardFooter {
  margin-left: 40px;
  font-family: 'Kenyan';
  color: #12c191;
  border-top: solid black;
  padding-top: 6px;
  margin-top: 4px;
}

.centeredSection {
  width: 90%;
  margin: auto;
  padding: 12px;
  border-radius: 16px;
}

.mainCard {
  width: 240px;
  box-shadow: 3px 3px 6px 0px #00000047;
  padding: 4px;
  background-color: white;
}

.shineHover figure {
  position: relative;
}

.shineHover figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.shineHover figure:hover::before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.round {
  border-radius: 16px;
}

.sround {
  border-radius: 6px;
}

.pad12 {
  padding: 12px;
}

.pad16 {
  padding: 16px;
}

.pad24 {
  padding: 24px;
}

.pad32 {
  padding: 32px;
}

.pad48 {
  padding: 48px;
}

.top4 {
  margin-top: 4px;
}

.top8 {
  margin-top: 8px;
}

.bot8 {
  margin-bottom: 8px;
}

.bot16 {
  margin-bottom: 16px;
}

.bot24 {
  margin-bottom: 24px;
}

.bot32 {
  margin-bottom: 32px;
}

.top12 {
  margin-top: 12px;
}

.top18 {
  margin-top: 18px;
}

.top24 {
  margin-top: 24px;
}

.top36 {
  margin-top: 36px;
}

.top48 {
  margin-top: 48px;
}

.left24 {
  margin-left: 24px;
}

.right6 {
  margin-right: 6px;
}

.right18 {
  margin-right: 18px;
}

.italic {
  font-style: italic;
}

.opinionsScroll {
  display: flex;
  align-items: flex-start;
  overflow-x: scroll;
  white-space: nowrap;
  padding: 10px;
  scroll-behavior: smooth;
  width: 80%;
  margin: 0 auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.opinionsScroll::-webkit-scrollbar {
  display: none;
}

.schoolLink {
  color: inherit;
  font-style: italic;
  text-decoration: none;
}

.schoolLink:hover {
  color: cornflowerblue;
}

.blackTextHover {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.blackTextHover:hover {
  color: cornflowerblue;
}

.blueTextHover {
  text-decoration: none;
  color: cornflowerblue;
  cursor: pointer;
}

.blueTextHover:hover {
  color: chocolate;
}

.whiteTextHover {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.whiteTextHover:hover {
  color: cornflowerblue;
}

.followedUserFrame {
  width: 260px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  padding-bottom: 8px;
}

.followedUserDiv {
  width: 240px;
  background-color: white;
  padding: 8px 12px;
  box-shadow: rgb(0 0 0 / 42%) 3px 3px 8px;
  height: 420px;
}

.newLabelDiv {
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  background-color: cornflowerblue;
  padding: 0px 2px 0px 2px;
  text-transform: uppercase;
  font-size: smaller;
  font-family: 'Mak', cursive;
}

.topCourse {
  position: absolute;
  top: 0px;
  right: 0px;
  background-image: url('./assets/top.png');
  background-size: cover;
  color: white;
  width: 36px;
  height: 46px;
  font-family: 'Kenyan';
  text-align: center;
  font-size: smaller;
  padding-top: 1px;
  filter: drop-shadow(-2px 2px 2px #00000061);
}

.divKindProduct {
  position: absolute;
  top: 4px;
  left: 8px;
  font-size: small;
  background-color: #f76429d6;
  font-style: italic;
  border-radius: 6px;
  padding: 0px 6px 0px 6px;
  color: white;
}

.itemSchool {
  text-align: right;
  font-size: small;
  cursor: pointer;
  color: darkolivegreen;
  margin-bottom: -12px;
}

.itemPicture {
  width: 80%;
  max-width: 580px;
  margin: 8px;
}

.planPrice {
  position: relative;
  margin: 16px;
  width: 160px;
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.planPriceLabel {
  font-family: 'Mak', cursive;
  font-size: min(7vw, 26px);
  color: black;
  margin: 0px;
}

.planOldPrice {
  position: absolute;
  left: 50%;
  bottom: 0px;
  font-size: large;
  font-weight: bold;
  color: hotpink;
  text-decoration-line: line-through;
  text-decoration-color: #a85780;
  text-decoration-thickness: 2px;  
}

.footer {
  padding: 8px;
  background-color: #212529;
  font-size: small;
  color: white;
  font-family: 'Kenyan', sans-serif;
}

.imgButton {
  cursor: pointer;
}

.imgButton:hover {
  filter: brightness(1.25);
}

.shopFilter {
  padding: 6px;
  border-style: solid;
  border-width: thin;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #def6ef;
  border-color: #12c191;
}

.studentsFilter {
  padding: 6px;
  border-style: solid;
  border-width: thin;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #def6ef;
  border-color: #12c191;
  margin-bottom: 12px;
}

.studentRow {
  display: flex;
  align-items: center;
  padding-left: 4px;
  margin-bottom: 6px;
  cursor: pointer;
  position: relative;
  background-color: white;
}

.studentRow:hover {
  background-color: ghostwhite;
}

.studentExpRow {
  border: solid #6f6548 thin;
  margin-bottom: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 8px;
  background-color: white;
}

.horizontal-scrolling-items {
  width: 100%;
  position: absolute;
  margin-top: -24px;
}

.horizontal-scrolling-words {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
}

.horizontal-scrolling-words span {
  display: inline-block;
  padding-left: 100%;
  animation-name: horizontal-scrolling-slide;
  animation-duration: 160s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.horizontal-scrolling-words--second span {
  animation-delay: 80s;
}

.fadeOut{
  opacity: 0;
  height: 0px;
  transition: height 0.25s 0.25s, opacity 0.25s;
  overflow-y: hidden;
}

.fadeOut2{
  opacity: 0;
  transition: opacity 0.25s;
}

.fadeIn{
  opacity: 1;
  transition: height 0.25s, opacity 0.25s 0.25s;
}

.fadeIn2{
  opacity: 1;
  transition: opacity 0.25s 0.25s;
}

@keyframes horizontal-scrolling-slide {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.horizontal-scrolling-first-slide {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
}

.horizontal-scrolling-first-slide span {
  display: inline-block;
  animation-name: horizontal-scrolling-slide-then-hide;
  animation-duration: 80s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes horizontal-scrolling-slide-then-hide {
  0% {
    transform: translate(0, 0);
  }
  100% {
    display: none;
    transform: translate(-100%, 0);
  }
}

.boldPost {
  font-weight: 500;
}

.italicPost {
  font-style: italic;
}

.underlinePost {
  text-decoration: underline;
}

.textPost {
  font-size: 1.2rem;
}

.bigTextPost {
  font-size: 1.5rem;
}

.smallTextPost {
  font-size: 1rem;
}

.tinyTextPost {
  font-size: 0.9rem;
  line-height: 1.1;
}

.medTextPost {
  font-size: 1.1rem;
}

.divExtension {
  padding: 6px;
  border-style: dotted;
  border-width: thin;
  font-style: italic;
  margin-right: 6px;
  border-radius: 8px;
  cursor: pointer;
}

.indexTextPost {
  font-size: medium;
  margin-left: 8px;
}

.chatCommentPane {
  margin-right: 24px;
  position: relative;
}

.chatCommentMessage {
  border-radius: 8px;
  box-shadow: 1px 1px 2px 0px rgb(0 0 0 / 20%);
  margin-left: 38px;
  margin-top: 8px;
  padding: 4px;
}

.commentCorner {
  width: 16px;
  height: calc(8px + 50%);
  position: absolute;
  left: 22px;
  top: -8px;
  border-left-style: solid;
  border-bottom-style: solid;
  border-bottom-left-radius: 8px;
  border-color: #a7a8a9;
  border-width: 2px;
}

.commentLine {
  width: 16px;
  height: calc(8px + 50%);
  position: absolute;
  left: 22px;
  top: calc(50% - 8px);
  border-left-style: solid;
  border-color: #a7a8a9;
  border-width: 2px;
}

.commentParentLine {
  position: absolute;
  border-left-style: solid;
  border-color: #a7a8a9;
  border-width: 2px;
  height: calc(100% + 8px);
  top: -8px;
}

.postvideodiv {
  display: flex;
  justify-content: center;
  margin: 8px 18px 12px 18px;
}

.postimgdiv {
  display: flex;
  justify-content: center;
  padding: 4px;
}

.clockDigitPane {
  text-align: center;
  background-color: #5e5842;
  color: white;
  width: 20%;
  margin-left: 4px;
  margin-right: 4px;
  max-width: 85px;
  border-style: ridge;
  border-radius: 8px;
  filter: drop-shadow(2px 4px 6px #5e584269);
}

.clockDigit {
  font-family: fantasy;
  font-size: x-large;
  margin-bottom: 0px;
}

.clockDigitText {
  font-family: serif;
  font-size: small;
  margin-bottom: 5px;
}

.calendarPane {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 16px;
}

.calendarDayPane {
  width: 96px;
  margin: 5px;
  filter: drop-shadow(2px 4px 6px black);
}

.calendarTopDay {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  text-align: center;
  font-size: small;
  font-family: monospace;
  position: relative;
  height: 24px;
  padding-top: 2px;
}

.calendarDay {
  border-left-style: solid;
  border-right-style: solid;
  text-align: center;
  font-family: sans-serif;
  font-weight: bold;
  font-size: large;
  padding-top: 6px;
  background-color: white;
  cursor: pointer;
}

.calendarCaption {
  border-left-style: solid;
  border-bottom-style: solid;
  border-right-style: solid;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  font-size: small;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 6px;
  line-height: 14px;
  display: flex;
  align-items: center;
  height: 38px;
  background-color: white;
  cursor: pointer;
  font-family: 'Kenyan', cursive;
}

.calendarDayInfo {
  color: #0000008a;
  cursor: pointer;
  position: absolute;
  left: 2px;
  top: 1px;
  background: none;
  border: none;
}

.calendarDayInfo:hover {
  transform: scale(1.25);
  color: black;
}

.postReaction {
  position: relative;
  display: flex;
  justify-content: space-evenly;
}

.postReactionButton {
  border-style: none;
  background-color: inherit;
  border-radius: 8px;
  padding: 2px 6px 2px 6px;
}

.postReactionButton:hover {
  background-color: gainsboro;
  transform: scale(1.1);
}

.postReactionButtonSmall {
  border-style: none;
  border-radius: 8px;
  font-size: smaller;
  background-color: inherit;
  border: 1px dashed rgba(0, 0, 0, 0);
}

.postReactionButtonSmall:hover {
  border: 1px dashed black;
}

.reactionOption {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: smaller;
  cursor: pointer;
  border-radius: 8px;
  border-style: double;
  border-color: antiquewhite;
  width: 72px;
  align-items: center;
  justify-content: center;
  margin: 0px 3px;
}

.reactionOption:hover {
  background-color: white;
  border-color: #0000002d;
}

.bookmarkDayPane {
  position: absolute;
  right: 4px;
  top: 22px;
  font-size: large;
  cursor: cell;
}

.bookmarkDayPane:hover {
  transform: scale(1.1);
}

.postimg {
  width: auto;
  height: auto;
  max-width: min(480px, 100%);
  max-height: 320px;
  filter: drop-shadow(5px 5px 5px #8f8f8f);
}

.taskPost {
  border-radius: 8px;
  box-shadow: 1px 1px 2px 0px rgb(0 0 0 / 20%);
  margin: 24px 24px 0px 24px;
  padding: 6px;
  position: relative;
}

.banner-section {
  display: flex;
  animation: banner-swipe var(--speed) linear infinite backwards;
}

@keyframes banner-swipe {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-100%);
  }
}

.banner-image {
  padding: 0 15px;
  object-fit: cover;
  cursor: pointer;
}

.banner-image:last-of-type {
  padding-left: 0;
}

.split {
  display: flex;
  flex-direction: row;
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
}

.topCourseMenu {
  background-color: #12c191;
  text-align: center;
  font-weight: bold;
  font-family: 'Kenyan', cursive;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-style: solid;
  border-width: thin;
  border-color: #0e9973;
  font-size: large;
}

.taskPostIt {
  width: 80%;
  height: 148px;
  margin-top: 16px;
  border-radius: 4px;
  box-shadow: -3px 3px 5px 0px #000000b0;
  position: relative;
  display: flex;
  align-items: center;
}

.taskPin {
  position: absolute;
  left: 0;
  top: 0;
}

.taskName {
  text-align: center;
  font-family: 'Mak', cursive;
  font-size: large;
  color: ghostwhite;
  font-weight: 600;
}

.taskReviewed {
  margin-top: 12px;
  color: antiquewhite;
  font-size: x-small;
}

.taskCard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.taskCardHeader {
  text-align: center;
  font-family: 'Mak', cursive;
  font-size: large;
  margin-top: 12px;
  width: 100%;
}

.chatPane {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-size: 520px;
}

.chatPost {
  border-radius: 8px;
  box-shadow: 1px 1px 2px 0px rgb(0 0 0 / 20%);
  margin: 24px 24px 0px 24px;
  padding: 6px;
  position: relative;
  background-color: #eeeeee;
}

.noCommentDivPost {
  font-size: 1.3rem;
  padding-top: 16px;
  padding-left: 8px;
  padding-right: 8px;
}

.commentDivPost {
  font-size: 1.3rem;
  overflow: hidden;
  word-wrap: break-word;
  white-space: normal;
}

.buttonGoTask {
  appearance: button;
  background-color: transparent;
  background-image: linear-gradient(to bottom, #e8e5d8, #f1e6a5);
  border: 0 solid #e5e7eb;
  border-radius: .5rem;
  box-sizing: border-box;
  color: #482307;
  column-gap: 1rem;
  cursor: pointer;
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 100%;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  outline: 2px solid transparent;
  padding: 1rem 1.5rem;
  text-align: center;
  text-transform: none;
  transition: all .1s cubic-bezier(.4, 0, .2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  box-shadow: -6px 8px 10px rgb(167 185 107 / 30%), 0px 2px 2px rgb(113 97 57 / 32%);
}

.buttonGoTask:active {
  background-color: #f3f4f6;
  box-shadow: -1px 2px 5px rgba(81, 41, 10, 0.15), 0px 1px 1px rgba(81, 41, 10, 0.15);
  transform: translateY(0.125rem);
}

.chatExpandButton {
  border-style: none;
  border-radius: 8px;
  font-size: small;
  margin-right: 2px;
  background-color: #12c191;
}

.chatExpandButton:hover {
  background-color: white;
}

.chatUserName {
  font-weight: 500;
  margin-bottom: 2px;
  font-size: small;
  color: dimgray;
}

.singleReaction {
  margin-right: 6px;
  font-size: small;
  display: inline-flex;
  align-items: flex-end;
  cursor: pointer;
}

.menuCommentButton {
  border-style: none;
  font-size: smaller;
  background-color: inherit;
}

.menuCommentButton:hover {
  transform: scale(1.1);
}

.menuCommentItem {
  font-size: medium;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.menuCommentItem:hover {
  background-color: burlywood;
}

.divUnitCircle {
  margin-right: 1rem;
  background-color: antiquewhite;
  width: 2rem;
  height: 2rem;
  font-size: 0.6875rem;
  line-height: 2rem;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  font-weight: 600;
}

.bookmarkPostPane {
  position: absolute;
  right: 4px;
  top: 0px;
  font-size: large;
  cursor: cell;
}

.bookmarkPostPane:hover {
  transform: scale(1.1);
}

.studentRow {
  display: flex;
  align-items: center;
  padding-left: 4px;
  margin-bottom: 6px;
  cursor: pointer;
  position: relative;
}

.studentRow:hover {
  background-color: ghostwhite;
}

.facebook {
  background-color: white;
  border-color: #295497;
  border-radius: 10px;
  padding: 6px;
}

.google {
  background-color: white;
  border-color: #f03800;
  border-radius: 10px;
  padding: 6px;
}

.form-control {
  margin-bottom: 10px;
}

.div-icon-eye {
  position: absolute;
  width: 28px;
  height: 18px;
  right: 8px;
  top: 10px;
  border-style: none;
  display: flex;
  font-size: 20px;
  color: lightcoral;
  cursor: pointer;
}

.userCoverImg {
  display: block;
  width: 100%;
  border-radius: 8px;
}

.userButtonCoverChange {
  background-color: white;
  color: black;
  position: absolute;
  right: 10px;
  top: 10px;
}

.userButtonCoverChange:hover {
  background-color: gray;
  filter: invert(1);
  color: black;
}

.userProfileDiv {
  position: absolute;
  left: 24px;
  bottom: 0;
  transform: translateY(50%);
  z-index: 100;
}

.userProfileFrame {
  width: 184px;
  aspect-ratio: 1;
  max-width: 30vw;
  overflow: hidden;
  border-radius: 50%;
  border-style: solid;
  border-width: thick;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-color: white;
}

.userCoverDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: flex-start;
}

.userProfileForm {
  background-color: white;
  padding: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 12px;
  box-shadow: -2px 2px 4px #00000036;
}

.userProfileContact {
  padding: 8px;
  border-radius: 8px;
  border: solid lightgray thin;
  margin-top: 26px;
}

.userPlanCard {
  width: 100%;
  max-width: 620px;
  margin: 16px 8px 16px 8px;
  background-color: ghostwhite;
  border-radius: 4px;
  box-shadow: -2px 2px 4px #00000030;
}

.teachercolumn {
  margin-top: 8px;
  padding: 8px;
  box-shadow: -4px 4px 12px #00000045;
  position: relative;
  width: 40%;
  min-width: min(420px,90%);
  margin-left: 2%;
  margin-right: 2%;
}

.teacherImg {
  width: 40%;
  max-width: 160px;
  border: double 6px transparent;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #f4e1a6, #997c21);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 12px;  
}

.teacherRole {
  font-size: min(3.5vw, 26px);
  color: #b69c4c;
  margin: 0px;
  font-family: fantasy;
}

.teacherName {
  font-size: min(3.5vw, 26px);
  font-weight: 600;
}

.userProfileContent {
  margin: 24px 5% 24px 5%;
}

.userProfileMenu {
  border-bottom: gray solid thin;
  display: flex;
}

.userProfileMenuItemActive {
  color: black;
  font-weight: 600;
  padding-bottom: 4px;
  border-bottom: black solid thick;
  margin: 0px 12px 0px 12px;
}

.userProfileMenuItemActive:hover {
  color: black;
}

.userProfileMenuItem {
  color: black;
  padding-bottom: 4px;
  margin: 0px 12px 0px 12px;
}

.userProfileMenuItem:hover {
  border-bottom: gray solid thick;
  color: black
}

.publicProfileSection {
  font-weight: 600;
  color: #837a5d;
  font-family: 'Mak', cursive;
  border-bottom: solid thin;
  margin-top: 16px;
}

.notificationText {
  margin: 0px 0px 6px 0px;
}

.chatCommentMessage {
  border-radius: 8px;
  box-shadow: 1px 1px 2px 0px rgb(0 0 0 / 20%);
  margin-left: 38px;
  margin-top: 8px;
  padding: 4px;
}

.chatPhotoPane {
  width: 36px;
  text-align: center;
}

.classGroupDiv {
  max-width: 380px;
  box-shadow: 2px 2px 4px 0px #00000059;
  padding: 8px;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 8px;
}

.chatPhoto {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  border-color: darkgray;
  font-family: 'Mak', cursive;
  font-size: 20px;
  color: white;
  text-transform: uppercase;
  line-height: 20px;
}

.chatPhotoBig {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  border-color: darkgray;
  font-family: 'Mak', cursive;
  font-size: 46px;
  color: white;
  text-transform: uppercase;
  line-height: 46px;
}

.userLink {
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.userLink:hover {
  color: firebrick;
}

.userActivityCard {
  width: calc(100% - 8px);
  background-color: white;
  margin: 24px 4px 24px 4px;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 2px 2px 5px #999999;
  max-width: 960px;
}

.ChatTextArea {
  background-color: white;
  font-family: sans-serif;
  min-height: 100px;
  resize: none;
  white-space: pre-wrap;
  display: flex;
}

.mimeButtonSmall {
  width: 150px;
  height: 90px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  background-image: url("./assets/mimeIcon.png");
}

.mimeButton {
  width: 190px;
  height: 114px;
  background-image: url("./assets/mimeIcon.png");
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mimeCaption {
  margin: 0;
  font-size: smaller;
  font-family: 'Mak', cursive;
  word-wrap: break-word;
}

.mimeCaptionSmall {
  margin: 0;
  font-size: smaller;
  font-family: 'Kenyan', cursive;
  word-wrap: break-word;
}

.divChest {
  background-color: #7f7f7f;
}

.imgChest {
  width: 100%;
  max-width: 600px;
}

.opinionImage {
  width: 200px;
  padding-left: 20px;
  cursor: pointer;
}

.opinionImage:hover {
  filter: saturate(140%);
}

.videoOpinionDialog {
  background-color: transparent;
  color: white;
}

.videoOpinionContent {
  background-color: #000000db;
}

.mimeSize {
  font-family: serif;
  font-style: italic;
  font-size: smaller;
}

.ChatToolButton {
  border: none;
  background: inherit;
  border-radius: 8px;
}

.ChatToolButton:hover {
  transform: scale(1.1);
  color: maroon;
}

.ChatToolButtonDisabled {
  border: none;
  background: inherit;
  border-radius: 8px;
  color: gray;
}

.chatImageUpload {
  max-width: 200px;
  max-height: 200px;
  border-style: dotted;
  border-radius: 10px;
  border-color: #c8ba7f;
  overflow: hidden;
  min-height: 80px;
  display: flex;
  align-items: center;
}

.chatPaneUpload {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.schoolLogo {
  width: 64px;
  height: 64px;
  filter: drop-shadow(5px 5px 5px #8f8e8e);
}

.medSchoolLogo {
  width: 92px;
  height: 92px;
  filter: drop-shadow(5px 5px 5px #8f8e8e);
  margin-bottom: 16px;
}

.smallSchoolLogo {
  width: 58px;
  height: 58px;
  filter: drop-shadow(5px 5px 5px #8f8e8e);
}

.accordion-body {
  background-color: ghostwhite;
}

.dropdown {
  background-color: white;
}

.menuDropDown {
  background-color: transparent;
}

.menuDropDown:hover {
  color: #ff7676;
}

.cabSchoolCard {
  background-color: #fcf7e5;
  padding: 10px;
  margin-bottom: 6px;
  margin-top: 6px;
  border-style: solid;
  border-width: thin;
  border-color: #b4af9e;
  border-radius: 6px;
  box-shadow: 2px 2px 4px 0px #0000004f;
}

.orderFrame {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0.5rem;
  padding: 4px;
  margin-top: 18px;
  background-color: white;
}

.orderItemFrame {
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  margin-right: 1rem;
}

.creditCardBase {
  background: #ccc;
  height: 300px;
  width: 595px;
  max-width: 100%;
  border-radius: 15px;
}

.creditCardInnerWrap {
  background-color: #0c0014;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%230d1838' points='1600%2C160 0%2C460 0%2C350 1600%2C50'/%3E%3Cpolygon fill='%230e315d' points='1600%2C260 0%2C560 0%2C450 1600%2C150'/%3E%3Cpolygon fill='%230f4981' points='1600%2C360 0%2C660 0%2C550 1600%2C250'/%3E%3Cpolygon fill='%231062a6' points='1600%2C460 0%2C760 0%2C650 1600%2C350'/%3E%3Cpolygon fill='%23117aca' points='1600%2C800 0%2C800 0%2C750 1600%2C450'/%3E%3C/g%3E%3C/svg%3E");
  background-size: auto 147%;
  background-position: center;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 13px;
  padding: 20px 40px;
  box-sizing: border-box;
  color: #fff;
}

.bookmarkOption {
  display: flex;
  font-size: small;
  cursor: pointer;
  border-radius: 8px;
  border-style: double;
  border-color: antiquewhite;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  min-width: 54px;
}

.bookmarkOption:hover {
  background-color: white;
  border-color: #0000002d;
}

.cartItemDiv {
  margin-left: 24px;
  margin-right: 24px;
}

.presentImage {
  width: 196px;
  height: 196px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: -2px 2px 5px 0px #00000085;
}

.cartItemImage {
  margin-left: 8px;
  border-radius: 8px;
  box-shadow: -2px 2px 5px 0px #00000085;
  width: 174px;
}

.itemImageCard {
  width: min(320px, 100%);
  aspect-ratio: 1.45; 
  height: auto;
  box-shadow: -2px 2px 5px 0px #00000085;
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cartPlanImage {
  margin-left: 8px;
  border-radius: 8px;
  box-shadow: -2px 2px 5px 0px #00000085;
  width: 174px;
}

.cartItemTitle {
  font-weight: 600;
  font-size: 1.2rem;
  margin: 0px;
}

.cartItemSubtitle {
  font-size: larger;
  font-style: italic;
}

.cartItemComment {
  margin: 0px;
}

.cartTableHeader {
  color: white;
  background-color: #12c191;
}

.cartItemPrice {
  margin: 0;
  font-weight: 600;
  font-size: large;
}

.cartRemoveItem {
  cursor: pointer;
  color: dimgray;
}

.cartRemoveItem:hover {
  color: black;
}

.cartBuyButton {
  background-color: #fac032;
  border-color: #d5a32b;
  color: black;
}

.cartBuyButton:hover {
  filter: brightness(1.05);
}

.preventSelect {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.projectDiv {
  width: 280px;
  word-wrap: break-word;
}

.projectDivSmall {
  width: 200px;
  word-wrap: break-word;
}

.projectFrame {
  background-color: white;
  margin: 8px;
  padding: 8px;
}

.profileCourseCard {
  max-width: 100%;
  width: 620px;
  background: white;
  margin: 12px;
  padding: 8px;
  border-radius: 6px;
  box-shadow: 2px 2px 5px #999999;
}

.taskUserCard {
  width: 180px;
  height: 80px;
  margin: 5px;
  background-color: cornsilk;
  border-radius: 8px;
  border-style: solid;
  border-color: #d6cfb7;
  border-width: thin;
  padding: 2px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}

.taskUserCardSel {
  width: 180px;
  height: 80px;
  margin: 5px;
  background-color: blanchedalmond;
  border-radius: 8px;
  border-style: double;
  border-color: #d7c6ad;
  border-width: medium;
  padding: 2px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
}

.taskReviewDiv {
  margin: 24px 48px 24px 24px;
  padding: 6px;
  display: flex;
}

.taskReview {
  border-radius: 8px;
  background-color: white;
  box-shadow: 1px 1px 2px 0px rgb(0 0 0 / 20%);
  padding: 6px;
  position: relative;
  width: 100%;
  border-style: outset;
}

.notificationsPane {
  width: 680px;
  max-width: 90vw;
  padding: 8px;
}

.notificationsTitle {
  text-transform: uppercase;
  font-size: 1rem;
  margin: 0px;
}

.notificationsLink {
  margin: 0px;
  font-size: small;
  font-family: serif;
  color: indigo;
  cursor: pointer;
}

.notificationsLink:hover {
  color: #b35bf3;
}

.notificationsMsgsPane {
  background-color: white;
  margin-top: 4px;
  margin-right: 4px;
  border-style: inset;
  border-width: thin;
  max-height: 75vh;
  overflow-y: auto !important;
  display: flex;
}

.notificationsMsg {
  padding: 4px 8px 4px 8px;
  border-bottom-style: inset;
  background-color: #dfeadd;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.notificationsMsg:hover {
  background-color: #b9d1b5;
}

.notificationsMsgRead {
  padding: 4px 8px 4px 8px;
  border-bottom-style: inset;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.notificationsMsgRead:hover {
  background-color: #e0d6b0;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.horizontal-scroll {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.scroll-container {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  cursor: grab;
  margin-left: 36px;
  margin-right: 36px;
}

.scroll-container:active {
  cursor: grabbing;
}

.scroll-button {
  background-color: transparent;
  width: 32px;
  height: 32px;
  border: none;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  z-index: 1;
}

.scroll-button:hover {
  filter: invert(32%) sepia(100%) saturate(1000%) hue-rotate(320deg);
}

.scroll-button.left {
  left: 0;
  background-image: url("./assets/left.png");
  background-size: contain;
}

.scroll-button.leftw {
  left: 0;
  background-image: url("./assets/leftw.png");
  background-size: contain;
}

.scroll-button.right {
  right: 0;
  background-image: url("./assets/right.png");
  background-size: contain;
}

.scroll-button.rightw {
  right: 0;
  background-image: url("./assets/rightw.png");
  background-size: contain;
}

.scroll-button.disabled {
  opacity: 0.5;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.terms-div {
  max-width: 720px;
  padding: 32px;
  margin: 24px auto;
  background-color: white;
  box-shadow: 0 1px 1px #090a0d14,0 1px 4px #090a0d1f,0 0 1px #7177843d;
  border-radius: 8px;
  width: calc(100% - 32px);
}

.terms-circle {
  background-color: white;
  box-shadow: 0 1px 1px #090a0d14,0 1px 4px #090a0d1f,0 0 1px #7177843d;
  position: fixed;
  padding: 24px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
}

.terms-circle:hover {
  box-shadow: 0 0 3px rgba(14,16,20,.08),0 4px 8px #090a0d1f,0 0 1px #7177843d,0 0 0 0 rgba(27,139,224,.32);
  color: dimgray;
}

ol {
  counter-reset: section;
  list-style-type: none;
  font-family: 'Prag', sans-serif;
  margin-top: 12px;
  padding-left: 0px;
}

ol[start] {
  counter-reset: section calc(var(--start) - 1);
}

ol li::before {
  counter-increment: section;
  content: counters(section, ".") ". ";
  font-weight: bold;
}

ol ol {
  margin-left: 20px;
}

ol ol li {
  margin-left: 10px;
}

.pterms {
  margin-bottom: 8px;
  text-align: justify;
  text-justify: inter-word;
}

.terms-ilist {
  list-style: circle;
}

.terms-ilist::before {
  content: '';
  counter-increment: none;
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

.hideScroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.imessage {
  display: flex;
  flex-direction: column-reverse;
  margin: 0 auto 1rem;
  max-width: 820px;
  padding: 0.5rem 2.5rem;
  overflow: auto;
}

.imessage div {
  border-radius: 1.15rem;
  max-width: 75%;
  padding: 0.5rem .875rem;
  position: relative;
  word-wrap: break-word;
}

.imessage div::before,
.imessage div::after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
}

div.from-me {
  align-self: flex-end;
  background-color: #d9f5ed;
  color: black; 
}

div.from-me::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid #d9f5ed;
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

div.from-me::after {
  background-color: #fef5de;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform:translate(-30px, -2px);
  width: 10px;
}

div[class^="from-"] {
  margin: 0.5rem 0;
  width: fit-content;
}

div.from-me ~ div.from-me {
  margin: 0.25rem 0 0;
}

div.from-me ~ div.from-me:not(:last-child) {
  margin: 0.25rem 0 0;
}

div.from-me ~ div.from-me:last-child {
  margin-bottom: 0.5rem;
}

div.from-them {
  align-items: flex-start;
  background-color: white;
  color: black;
}

div.from-them:before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid white;
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}

div.from-them::after {
  background-color: #fef5de;
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
}

div.msg-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  margin: 0px 0px 8px 0px;
  cursor: pointer;
  border-radius: 4px;
}

div.msg-contact:hover {
  background-color: #d9f5ed;
}

.sendMessageBox {
  border: solid #12c191 3px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

@-webkit-keyframes pulse {
  from { transform: scale(1) translate(6vw, -35%); }
  to { transform: scale(1.1) translate(6vw, -35%); }
}

@-moz-keyframes pulse {
  from { transform: scale(1) translate(6vw, -35%); }
  to { transform: scale(1.1) translate(6vw, -35%); }
}

@keyframes pulse {
  from { transform: scale(1) translate(6vw, -35%); }
  to { transform: scale(1.1) translate(6vw, -35%); }
}

.animatePulse {
  -webkit-animation: pulse 2s ease alternate infinite;
  -moz-animation: pulse 2s ease alternate infinite;
  animation: pulse 2s ease alternate infinite;  
}

@-webkit-keyframes pulseNoMove {
  from { transform: scale(1); }
  to { transform: scale(1.1); }
}

@-moz-keyframes pulseNoMove {
  from { transform: scale(1); }
  to { transform: scale(1.1); }
}

@keyframes pulseNoMove {
  from { transform: scale(1); }
  to { transform: scale(1.1); }
}

.animatePulseNoMove {
  -webkit-animation: pulseNoMove 2s ease alternate infinite;
  -moz-animation: pulseNoMove 2s ease alternate infinite;
  animation: pulseNoMove 2s ease alternate infinite;  
}

@media (max-width: 640px) {
  h1 {
      font-size: xx-large;
  }
  .huge {
    font-size: 3em;
  }
  .terms-div {
    width: calc(100% - 8px);
    margin: 4px;
  }
}
